import { http, HttpResponse } from 'msw'

import { allServices } from 'app/mocks/data/users/services'
import { api, isNumeric } from 'app/utils/constants'

export const getAllServices = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.SERVICES}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get('client__client__id__in')
        const clientIdsFilter = url.searchParams.get('client__id__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allServicesFiltered = allServices

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = managerIdsFilter.split(',')

            allServicesFiltered = allServicesFiltered.filter((service) =>
                managerIdsSplit.includes(service.client.client.id.toString()),
            )
        }

        // Client filter
        if (!!clientIdsFilter) {
            const clientIdsSplit = clientIdsFilter.split(',')

            allServicesFiltered = allServicesFiltered.filter((service) =>
                clientIdsSplit.includes(service.client.id.toString()),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allServicesFiltered = allServicesFiltered.filter((service) =>
                service.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allServicesFiltered.length > limit
                ? offset + limit
                : allServicesFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allServicesFiltered.slice(offset, limitArray)
                : allServicesFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allServicesFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allServicesFiltered.length,
            next,
            results,
        })
    },
)

import { clients } from './clients'
import { diagnostics } from './diagnostics'
import { machineRelations } from './machineRelations'
import { machines } from './machines'
import { sites } from './sites'

export const park = [
    ...clients,
    ...diagnostics,
    ...machineRelations,
    ...machines,
    ...sites,
]

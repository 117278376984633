import { z } from 'zod'

/** Enum machine type. */
export const TypeMachine = z.enum([
    'DISTRIBUTOR',
    'FRIDGE_RFID',
    'FRIDGE_WEIGHT',
    'LOCKER_COLD',
    'LOCKER_DRY',
    'TERMINAL',
])

/** Machine order available. */
export const OrderMachine = z.enum(['A', 'B', 'C', 'D', 'E', 'F'])

import { park } from './handlers/park'
import { products } from './handlers/products'
import { users } from './handlers/users'

const handlers = [...park, ...products, ...users]

/** Enable mocks depending on env variables. */
export const enableMocks = async () => {
    if (
        process.env.NODE_ENV !== 'development' ||
        process.env.REACT_APP_MOCKING_ENABLED !== 'true'
    )
        return

    const isServer = typeof window === 'undefined'

    // Server
    // if (isServer) {
    //     const { setupServer } = await import('msw/node')

    //     setupServer(...handlers).listen()
    // }

    // Browser
    if (!isServer) {
        const { setupWorker } = await import('msw/browser')

        await setupWorker(...handlers).start({
            onUnhandledRequest(req, print) {
                const path = new URL(req.url).pathname

                if (
                    path?.startsWith('/_next') ||
                    path?.startsWith('/__next') ||
                    path?.startsWith('/static') ||
                    req.url.startsWith('https://fonts.gstatic.com') ||
                    req.url.startsWith('https://a.tile.openstreetmap.org') ||
                    req.url.startsWith('https://b.tile.openstreetmap.org') ||
                    req.url.startsWith('https://c.tile.openstreetmap.org') ||
                    req.url.startsWith('https://unpkg.com')
                )
                    return

                print.warning()
            },
        })
    }
}

export const DELAY_DEBOUNCE = 1_000
export const MAX_IMAGE_SIZE = 1_024 * 1_024 * 2
export const PAGINATION_LIMIT = 20

export const links = {
    KONVIVES: 'https://konvives.com',
    TICKETING: 'https://e-thik.odoo.com',
}

/** Make a pause with the time given (in milliseconds). */
export const pause = async (time) => {
    await new Promise((resolve) => setTimeout(resolve, time))
}

/** Know if the value passed is a number or not. */
export const isNumeric = (numberToTest) =>
    (typeof numberToTest === 'number' ||
        (typeof numberToTest === 'string' && numberToTest.trim() !== '')) &&
    !isNaN(numberToTest)

/** Konsole users roles availables. */
export const roles = {
    DELIVERY_MEN: 'Approvisionneur',
    MANAGER: 'Gestionnaire',
    TECHNICIAN: 'Technicien',
}

/** Transform the query into an array of number. */
export const queryIntoArrayNumber = (query) =>
    query
        .split(',')
        .map(Number)
        .filter((value) => !Number.isNaN(value))

export const api = {
    ALLERGENS: 'allergens/',
    EMAIL_PARAMETERS: 'email_parameters/',
    MACHINES_CATEGORIES: 'categories_machines/',
    PRODUCTS_CATEGORIES: 'categories_products/',
    PRODUCTS_SUB_CATEGORIES: 'sub_categories_products/',
    PRODUCTS_IMPORT: 'import/products/',
    REFUND: 'refund/',
    SITES: 'sites/',
    SITES_TYPES: 'site_types/',
    STATS_STOCK: 'stats_stock',
    TICKET_PARAMETERS: 'ticket_parameters/',
    UPLOAD_SIGNATURE: 'images_sign_upload/',
    ZONES: 'zones/',

    auth: {
        ME: 'me',
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
    },

    park: {
        CLIENTS: 'site_customer/',
        DIAGNOSTIC: 'diagnostic/',
        MACHINE_RELATIONS: 'machines_grid/',
        MACHINES: 'machines/',
        PLANOGRAMS: 'machines_grid_items/',
    },

    products: {
        PRODUCTS: 'products/',
        SUPPLIERS: 'supplier_products/',
    },

    transactions: {
        BLOCKED_CARDS: 'blocked_cards/',
        SALES: 'sales/',
    },

    users: {
        KONSOLE: 'users/',
        KONSOLE_IMPERSONATE: 'users/impersonate/',
        RIGHTS: 'users_groups/',
        SERVICES: 'users/services/',

        konvives: {
            CREATION_LINK: 'users/konvives/creation-link',
            IMPORT: 'machines_users/import/',
        },

        managers: {
            LIST: 'clients/',

            categories: {
                LIST: 'client_category',
            },
        },
    },
}

import { http, HttpResponse } from 'msw'
import { api } from 'app/utils/constants'

import { allSites } from 'app/mocks/data/park/sites'

export const getAllSites = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.SITES}`,
    () => {
        return HttpResponse.json({
            count: allSites.length,
            next: false,
            results: allSites,
        })
    },
)

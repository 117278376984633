import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allMachines } from 'app/mocks/data/park/machines'
import { api } from 'app/utils/constants'

export const getMachine = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.MACHINES}:id/`,
    ({ params }) => {
        const { id } = params
        const machineFound = allMachines.find(
            (machine) => machine.id.toString() === id,
        )

        // Not found
        if (!machineFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json(machineFound)
    },
)
